import clsx from "clsx";
import React from "react";
import { Icon } from "~/components/ui/icons";
import { Link as LinkComponent } from "~/components/ui/link";
import {
	NavSection,
	SecondaryNavSection,
} from "~/components/mobilenav/nav-section";
import type {
	NavigationDocument,
	NavSubMenu,
	Link,
} from "~/types/sanity-schema";
import { asLink } from "~/utils/sanity-helpers";
import { Paragraph } from "~/components/ui/typography";
import { ButtonLink } from "~/components/ui/button";
import { RemoveScroll } from "react-remove-scroll";
import { LanguageSelector } from "~/components/language-selector";
import {
	type Location,
	type Navigation,
	useNavigation,
	useLocation,
} from "@remix-run/react";

import { matchPath } from "react-router-dom";
import { SearchButton } from "../algolia-search/search";
import { spTrackWebInteraction } from "~/utils/tracking";
import { usePageAvailableLocales } from "~/hooks/common";
import { useLocale, useSharedContent } from "~/hooks/localization";
import { externalLinks } from "~/utils/external-links";

interface MobileNavProps {
	navigationData: NavigationDocument;
}

export function useMobileMenu() {
	const [isOpen, setOpen] = React.useState(false);

	const navigation = useNavigation();

	const isNavigating = navigation.state === "loading";
	// close menu when navigating
	React.useEffect(() => {
		setOpen(false);
	}, [isNavigating]);

	function toggleMenu() {
		setOpen((prevOpen) => {
			spTrackWebInteraction({
				object: "hamburger menu",
				action: "toggle",
				value: !prevOpen ? "open" : "close",
			});
			return !prevOpen;
		});
	}

	return { isOpen, setOpen, toggleMenu };
}

export function MobileMenu({ navigationData }: MobileNavProps) {
	const { isOpen, setOpen, toggleMenu } = useMobileMenu();
	const location = useLocation();
	const lang = useLocale();
	const isEngPage = lang === "en";

	const navItems = navigationData.items;
	const [activeMenu, setActiveMenu] = React.useState<string | null>();

	const { primaryCTA, secondaryCTA } = navigationData;
	const primaryCTALink = asLink(primaryCTA);
	const secondaryCTALink = asLink(secondaryCTA);

	const availableLocales = usePageAvailableLocales();
	const shouldShowLanguageSelector = availableLocales.length > 0;

	const { t } = useSharedContent();

	// reset menu to home state when it closes
	React.useEffect(() => {
		if (!isOpen) {
			setActiveMenu(null);
		}
	}, [isOpen]);

	function navSectionClick(id: string) {
		if (activeMenu === id) {
			setActiveMenu(null);
		} else {
			setActiveMenu(id);
		}
	}

	const handleEscape = (event: React.KeyboardEvent) => {
		if (event.key === "Escape" && isOpen) {
			setOpen(false);
		}
	};

	const loginItem: Link = {
		_type: "link",
		title: t("login"),
		externalUrl: externalLinks.consoleLogin,
		slug: null,
		locale: null,
	};

	if (!navigationData) {
		return null;
	}

	return (
		<div
			onKeyUp={(event) => {
				handleEscape(event);
			}}
		>
			<div className="flex gap-3">
				{isEngPage ? <SearchButton /> : null}
				{shouldShowLanguageSelector ? (
					<div className="flex h-9 w-9 items-center justify-center">
						<LanguageSelector iconOnly={true} />
					</div>
				) : null}
				<button
					aria-expanded={isOpen}
					onClick={toggleMenu}
					className="flex h-9 w-9 items-center justify-center"
					aria-controls="global-navigation-menu"
					aria-label={
						isOpen ? "Close global navigation" : "Open global navigation"
					}
				>
					{isOpen ? (
						<Icon name="close-button" color="primary" />
					) : (
						<Icon name="nav-button" color="primary" />
					)}
				</button>
			</div>
			<div
				id="global-navigation-menu"
				className={clsx(
					{
						"fixed bottom-0 left-0 right-0 top-mobile-nav z-10": isOpen,
					},
					{ hidden: !isOpen },
					"bg-primary py-6"
				)}
			>
				<RemoveScroll enabled={isOpen}>
					{navItems
						? [...navItems, loginItem].map((navItem, index) => {
								const { title } = navItem;

								if (navItem._type === "link") {
									const url = asLink(navItem);

									const isActive = url === location.pathname;

									const isExternal =
										Boolean(navItem?.externalUrl) && !navItem?.internalLink;

									return url ? (
										<LinkComponent
											key={index}
											to={url}
											trackingText={title}
											prefetch="viewport"
											trackingPosition="menu"
											className={clsx(
												"hover:bg-secondary block w-full gap-2 px-6 py-5 text-left",
												{
													"text-primary": isActive,
												}
											)}
										>
											<Paragraph
												as="span"
												size="button"
												color="current"
												className="inline-flex items-center gap-3"
											>
												{title}
												{isExternal ? (
													<Icon
														name="external-link"
														color="primary"
														width="14"
														height="14"
													/>
												) : null}
											</Paragraph>
										</LinkComponent>
									) : null;
								}

								if (!title) return null;

								const id = navItem._key;

								return (
									<NavSection
										navLinkGroups={navItem.navLinkGroups}
										key={index}
										id={id}
										active={activeMenu === id}
										onClick={navSectionClick}
										buttonName={title}
									/>
								);
							})
						: []}
				</RemoveScroll>
				<div className="border-stroke absolute bottom-0 flex w-full flex-col gap-5 border-t bg-[white] px-7 py-6">
					{primaryCTA && primaryCTALink ? (
						<ButtonLink variant="secondary" to={primaryCTALink}>
							{primaryCTA.title}
						</ButtonLink>
					) : null}
					{secondaryCTA && secondaryCTALink ? (
						<ButtonLink to={secondaryCTALink}>{secondaryCTA.title}</ButtonLink>
					) : null}
				</div>
			</div>
		</div>
	);
}

interface SecondaryMobileNavProps {
	navigationData: NavigationDocument;
}

const isNavSubMenu = (item: Link | NavSubMenu): item is NavSubMenu =>
	item._type === "navSubmenu";

const isNavLink = (item: Link | NavSubMenu): item is Link =>
	item._type === "link";

function isMatch(
	pathname: string,
	navigation: Navigation,
	currentLocation: Location
) {
	const location =
		navigation.location && !navigation.formData
			? navigation.location
			: currentLocation;
	const match = matchPath(pathname, location.pathname);
	return Boolean(match);
}

function ActiveSubMenuTitle({
	items: navItems,
	currentLocation,
}: Required<
	Pick<NavigationDocument, "items"> & { currentLocation: Location }
>) {
	const navigation = useNavigation();

	const firstNavItem = navItems[0];

	const activeMenuItem = navItems.find((navItem) => {
		if (isNavSubMenu(navItem)) {
			return navItem.navSubmenuItems?.some((item) => {
				const navLink = asLink(item);

				return navLink ? isMatch(navLink, navigation, currentLocation) : false;
			});
		}

		if (isNavLink(navItem)) {
			const url = asLink(navItem);
			return url ? isMatch(url, navigation, currentLocation) : false;
		}

		return false;
	});

	return (
		<Paragraph fontWeight="font-medium">
			{activeMenuItem?.title || firstNavItem?.title}
		</Paragraph>
	);
}

export function SecondaryMobileMenu({
	navigationData,
}: SecondaryMobileNavProps) {
	const { isOpen, setOpen, toggleMenu } = useMobileMenu();
	const currentLocation = useLocation();

	const navItems = navigationData.items;
	const [activeMenu, setActiveMenu] = React.useState<string | null>();

	// reset menu to home state when it closes
	React.useEffect(() => {
		if (!isOpen) {
			setActiveMenu(null);
		}
	}, [isOpen]);

	function navSectionClick(id: string) {
		if (activeMenu === id) {
			setActiveMenu(null);
		} else {
			setActiveMenu(id);
		}
	}

	const handleEscape = (event: React.KeyboardEvent) => {
		if (event.key === "Escape" && isOpen) {
			setOpen(false);
		}
	};

	return (
		<div
			onKeyUp={(event) => {
				handleEscape(event);
			}}
			className="relative h-full w-full"
		>
			<button
				onClick={toggleMenu}
				className="border-stroke flex h-full w-full items-center justify-between border-b px-6"
			>
				{!navItems ? null : (
					<ActiveSubMenuTitle
						items={navItems}
						currentLocation={currentLocation}
					/>
				)}
				<span className="flex h-9 w-9 items-center justify-center">
					{isOpen ? (
						<>
							<span className="sr-only">Close menu</span>
							<Icon name="close-button" color="primary" />
						</>
					) : (
						<>
							<span className="sr-only">Open menu</span>
							<Icon name="plus" color="primary" />
						</>
					)}
				</span>
			</button>
			<RemoveScroll enabled={isOpen}>
				<div
					className={clsx(
						{
							"border-stroke bg-secondary absolute left-0 right-0 top-full flex max-h-[66vh] flex-col overflow-y-scroll overscroll-contain border-b py-3":
								isOpen,
						},
						{ hidden: !isOpen }
					)}
				>
					{navItems
						? navItems.map((navItem, index) => {
								const { title } = navItem;

								if (navItem._type === "link") {
									const url = asLink(navItem);

									const active = url === currentLocation.pathname;

									return url ? (
										<LinkComponent
											key={index}
											to={url}
											trackingText={title}
											trackingPosition="menu"
											className={clsx(
												"block w-full gap-2 px-6 py-5 text-left decoration-theme-primary underline-offset-8 hover:underline",
												{
													underline: active,
												}
											)}
										>
											<Paragraph as="span" size="button" color="current">
												{title}
											</Paragraph>
										</LinkComponent>
									) : null;
								}

								if (!title) return null;

								const id = navItem._key;

								return (
									<SecondaryNavSection
										navSubmenuItems={navItem.navSubmenuItems}
										key={index}
										id={id}
										active={activeMenu === id}
										onClick={navSectionClick}
										buttonName={title}
									/>
								);
							})
						: []}
				</div>
			</RemoveScroll>
		</div>
	);
}
