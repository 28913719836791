import { Paragraph } from "../ui/typography";
import { Link } from "~/components/ui/link";
import { Icon } from "../ui/icons";
import type { NavItem } from "~/types/sanity-schema";
import { asLink } from "~/utils/sanity-helpers";
import {
	getImageBuilder,
	getImageProps,
	getImageAltProp,
} from "~/utils/images";
import { Image } from "~/components/ui/image";

interface Props {
	headerText: string;
	items?: Array<NavItem>;
}

export function SubNavLinkGroup({ headerText, items }: Props) {
	if (!items) {
		return null;
	}

	return (
		<div>
			<Paragraph size="overline" className="mb-3" color="theme-secondary">
				{headerText}
			</Paragraph>
			<ul>
				{items.map((item, index) => {
					const { navLink } = item;
					const url = asLink(navLink);
					const isExternal =
						Boolean(navLink?.externalUrl) && !navLink?.internalLink;

					const icon = item.icon;
					const image = item.image;
					const navTitle = navLink?.title;

					if (!url) return null;

					return (
						<li key={index}>
							{item.variant === "highlight" ? (
								<Link
									aria-label={navLink?.title}
									className="group flex flex-col gap-3 p-3"
									to={url}
									trackingText={navTitle}
									trackingPosition="menu"
								>
									{image ? (
										<div className="bg-secondary aspect-[16/9] w-full max-w-[400px] overflow-hidden rounded-lg">
											<Image
												className="h-full w-full object-cover"
												{...getImageProps(
													getImageBuilder(image, {
														alt: getImageAltProp(image),
													}),
													{
														widths: [400, 500, 800],
														sizes: ["(min-width:1280px) 300px", "50vw"],
													}
												)}
											/>
										</div>
									) : null}
									<div>
										<Paragraph
											color="navigation"
											fontWeight="font-medium"
											size="button-small"
											className="mr-4 decoration-theme-primary underline-offset-8 group-hover:underline"
										>
											<span className="mr-2">{navTitle}</span>

											<Icon
												name="arrow-right"
												color="primary"
												height="14"
												width="14"
												className="inline-block transition-all group-hover:translate-x-2"
											/>
										</Paragraph>
									</div>
								</Link>
							) : (
								<Link
									aria-label={navLink?.title}
									className="group flex gap-3 rounded-lg p-3 hover:bg-grey-5"
									to={url}
									trackingText={navLink?.title}
									trackingPosition="menu"
								>
									{icon ? (
										<Image
											width="24px"
											height="24px"
											className="h-6 w-6 rounded-full object-cover"
											{...getImageProps(
												getImageBuilder(icon, {
													alt: getImageAltProp(icon),
												})?.width(24)
											)}
										/>
									) : null}
									<div>
										<Paragraph
											size="body-small"
											className="mb-1 inline-flex items-center gap-2"
											color="current"
											fontWeight="font-medium"
										>
											{navLink?.title}
											{isExternal ? (
												<Icon
													name="external-link"
													height="14"
													width="14"
													color="primary"
												/>
											) : null}
										</Paragraph>
										<Paragraph
											size="body-small"
											color="current"
											className="text-secondary group-hover:text-primary"
										>
											{navLink?.caption}
										</Paragraph>
									</div>
								</Link>
							)}
						</li>
					);
				})}
			</ul>
		</div>
	);
}
